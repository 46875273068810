// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aperitivo-js": () => import("../src/pages/aperitivo.js" /* webpackChunkName: "component---src-pages-aperitivo-js" */),
  "component---src-pages-aperitivoramen-js": () => import("../src/pages/aperitivoramen.js" /* webpackChunkName: "component---src-pages-aperitivoramen-js" */),
  "component---src-pages-bigbang-js": () => import("../src/pages/bigbang.js" /* webpackChunkName: "component---src-pages-bigbang-js" */),
  "component---src-pages-demarrage-js": () => import("../src/pages/demarrage.js" /* webpackChunkName: "component---src-pages-demarrage-js" */),
  "component---src-pages-erosion-js": () => import("../src/pages/erosion.js" /* webpackChunkName: "component---src-pages-erosion-js" */),
  "component---src-pages-everydaystone-js": () => import("../src/pages/everydaystone.js" /* webpackChunkName: "component---src-pages-everydaystone-js" */),
  "component---src-pages-howitbegan-js": () => import("../src/pages/howitbegan.js" /* webpackChunkName: "component---src-pages-howitbegan-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ladyday-js": () => import("../src/pages/ladyday.js" /* webpackChunkName: "component---src-pages-ladyday-js" */),
  "component---src-pages-lostinthebuzz-js": () => import("../src/pages/lostinthebuzz.js" /* webpackChunkName: "component---src-pages-lostinthebuzz-js" */),
  "component---src-pages-platform-5-js": () => import("../src/pages/platform5.js" /* webpackChunkName: "component---src-pages-platform-5-js" */),
  "component---src-pages-ramen-js": () => import("../src/pages/ramen.js" /* webpackChunkName: "component---src-pages-ramen-js" */),
  "component---src-pages-storytelling-js": () => import("../src/pages/storytelling.js" /* webpackChunkName: "component---src-pages-storytelling-js" */)
}

